import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/vercel/path0/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_KlVwwuJRCL from "/vercel/path0/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import composition_sLxaNGmlSL from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import app_init_hybrid_5gOhL5QG1b from "/vercel/path0/plugins/app-init-hybrid.js";
import app_init_client_lDwNIL1JCF from "/vercel/path0/plugins/app-init.client.js";
import print_client_Gob6ZST0tC from "/vercel/path0/plugins/print.client.js";
import recaptcha_W4US4xmBV6 from "/vercel/path0/plugins/recaptcha.js";
import sentry_client_GoGQuZo4Et from "/vercel/path0/plugins/sentry.client.js";
import vue_gtag_S6KjvYXIWb from "/vercel/path0/plugins/vue-gtag.js";
import vue_scroll_lock_client_nDk5Yuj1aw from "/vercel/path0/plugins/vue-scroll-lock.client.js";
import vue_social_sharing_client_1N3fV7xgEB from "/vercel/path0/plugins/vue-social-sharing.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_KlVwwuJRCL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  app_init_hybrid_5gOhL5QG1b,
  app_init_client_lDwNIL1JCF,
  print_client_Gob6ZST0tC,
  recaptcha_W4US4xmBV6,
  sentry_client_GoGQuZo4Et,
  vue_gtag_S6KjvYXIWb,
  vue_scroll_lock_client_nDk5Yuj1aw,
  vue_social_sharing_client_1N3fV7xgEB
]